import React from 'react';
const Section = ({ children, className }) => {
  return (
    <div className={className}>
      <div className="container mx-auto">{children}</div>
    </div>
  );
};

export default Section;

import React from 'react';
import Helmet from 'react-helmet';

import Header from '../Header';
import Footer from '../Footer';

import './layout.css';

function Layout({ children, withScroll = true }) {
  return (
    <main className="text-white">
      <Helmet></Helmet>

      <Header withScroll={withScroll} />

      <div className="mx-auto">{children}</div>

      <Footer />
    </main>
  );
}

export default Layout;

export const capitalize = (text) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
};

export const setActive = (e, id) => {
  if (document.querySelector(`#${id} button.active`) !== null) {
    document.querySelector(`#${id} button.active`).classList.remove('active');
  }
  if (e) {
    e.target.className += ' active';
  }
};

export const setBubble = (range, bubble) => {
  const val = range.value;
  const min = range.min ? range.min : 0;
  const max = range.max ? range.max : 100;
  const newVal = Number(((val - min) * 100) / (max - min));
  bubble.innerHTML = `${formatter.format(val)} min/mo`;
  bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
};

export const resetBubble = (bubble) => {
  if (bubble) {
    bubble.innerHTML = '0 min/mo';
    bubble.style.left = '10px';
  }
};

const isNumberInRange = (myNumber, min, max) => {
  const mini = Math.min.apply(Math, [min, max]),
    maxi = Math.max.apply(Math, [min, max]);
  return myNumber >= mini && myNumber <= maxi;
};

export const getRange = (minutes, rangeValues) => {
  for (const [index, rangeValue] of rangeValues.entries()) {
    const selectedRange = isNumberInRange(
      minutes,
      rangeValue.min,
      rangeValue.max
    );
    if (selectedRange) {
      return index + 1;
    }
  }

  return 0;
};

export const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0
});

export const getPricesPerPlan = (plans, minutes) => {
  let remainingMinutes = minutes;
  return plans.map((plan) => {
    const planMinutes =
      remainingMinutes <= plan.minutes ? remainingMinutes : plan.minutes;
    remainingMinutes -= planMinutes;
    return {
      title: plan.title,
      price: plan.pricePerMin * planMinutes,
      minutes: planMinutes,
      key: plan.key
    };
  });
};

export const getTotalPriceFromPlans = (plans) => {
  return plans.reduce((acc, p) => acc + p.price, 0);
};

export const debounce = (fn, ms) => {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      // eslint-disable-next-line no-undef
      fn.apply(this, arguments);
    }, ms);
  };
};

import React from 'react';
import { Link } from 'gatsby';
import pikselLogo from '../../images/Piksel.svg';

function Footer() {
  return (
    <footer className="text-white bg-black pt-11 pb-11">
      <div className="px-5 md:px-14 md:px-0 container mx-auto flex-none md:flex">
        <div className="flex-1 mr-1 pb-5 md:pb-0">
          <Link className="" to="https://piksel.com">
            <img src={pikselLogo} className="h-12" alt="Piksel" />
          </Link>
        </div>

        <ul className="flex-initial text-primary font-thin mr-16 pb-5 md:pb-0">
          <li>
            <Link className="font-medium" to="/">
              Piksel m³
            </Link>
          </li>
          <li>
            <Link className="" to="/#featuresSmall">
              Features
            </Link>
          </li>
          <li>
            <Link className="" to="/#benefitsSmall">
              Benefits
            </Link>
          </li>
          <li>
            <Link className="" to="/#usesSmall">
              Uses
            </Link>
          </li>
          <li>
            <Link className="" to="/#integrationSmall">
              Integration
            </Link>
          </li>
          <li>
            <Link className="" to="/#pricingSmall">
              Pricing
            </Link>
          </li>
          <li>
            <Link className="" to="/#evaluateSmall">
              Evaluate
            </Link>
          </li>
        </ul>
      </div>
      <div className="container mx-auto flex-none md:flex font-extralight">
        <ul className="px-5 md:px-14 md:px-0 text-primary flex-none md:flex pt-5">
          <li className="mr-4 pl-0">
            <a className="hover:text-white" href="https://www.pikselgroup.com">
              ©{new Date().getFullYear()} Piksel, Inc. All right reserved.
            </a>
          </li>
          <li className="flex-none mr-4 md:pl-4">
            <Link to="/privacy-policy">
              <a className="hover:text-white">Privacy policy</a>
            </Link>
          </li>
          <li className="mr-4 pl-0 md:pl-4">
            <Link to="/terms-and-conditions">
              <a className="hover:text-white">Terms and conditions</a>
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;

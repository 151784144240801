import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import * as Scroll from 'react-scroll';
import { Transition } from '@tailwindui/react';
import { debounce } from '../../helpers';
import pikselm3Logo from '../../images/Piksel-m3.svg';

function Header({ withScroll = true }) {
  const LinkReact = Scroll.Link;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setMobileMenuOpen(false);
    }, 1000);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  return (
    <header className="fixed w-screen bg-black text-white pt-4 pb-4 z-10">
      <div className="px-5 md:px-14 md:px-0 container mx-auto flex">
        <div className="w-5/6 lg:w-1/4 flex content-center">
          <Link className="" to="/">
            <img
              src={pikselm3Logo}
              className="mr-1 h-12"
              alt="Piksel m³ Media Matching Machine - Home"
            />
          </Link>
        </div>

        {/* // Burger Button */}
        <div className="lg:hidden origin-top-right right-0 w-1/5 pr-11 relative">
          <button
            className="w-8 h-8 text-white absolute right-11"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <svg
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        <Transition
          show={mobileMenuOpen}
          enter="duration-0"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-0"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* // Menu for small devices */}
          <div
            className="lg:hidden absolute origin-top-right left-0 right-0 mt-16 w-100
            shadow-lg py-1 bg-black text-white ring-1 ring-black
            ring-opacity-5 focus:outline-none"
            role="menu"
          >
            <ul className="container mx-auto flex flex-col lg:flex-row list-none ml-auto">
              <li className="py-1.5 leading-8">
                <Link
                  to="/#featuresSmall"
                  className="px-12 hover:text-primary"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  Features
                </Link>
              </li>
              <li className="py-1.5 leading-8">
                <Link
                  to="/#benefitsSmall"
                  className="px-12 hover:text-primary"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  Benefits
                </Link>
              </li>
              <li className="py-1.5 leading-8">
                <Link
                  to="/#usesSmall"
                  className="px-12 hover:text-primary"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  Uses
                </Link>
              </li>
              <li className="py-1.5 leading-8">
                <Link
                  to="/#integrationSmall"
                  className="px-12 hover:text-primary"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  Integration
                </Link>
              </li>
              <li className="py-1.5 leading-8">
                <Link
                  to="/#pricingSmall"
                  className="px-12 hover:text-primary"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  Pricing
                </Link>
              </li>
              <li className="py-1.5 leading-8">
                <Link
                  to="/#evaluateSmall"
                  className="px-12 hover:text-primary"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  Evaluate
                </Link>
              </li>
            </ul>
          </div>
        </Transition>

        {/* // Menu for large devices */}
        <div className="hidden lg:contents flex lg:flex-grow items-center text-primary">
          <ul
            className="flex flex-col lg:flex-row list-none ml-auto
          space-x-4 items-center"
          >
            <li className="">
              {withScroll ? (
                <LinkReact
                  to="features"
                  className="p-3 cursor-pointer hover:text-white"
                  activeClass="activeNav"
                  spy={true}
                  smooth={true}
                  offset={-50}
                >
                  Features
                </LinkReact>
              ) : (
                <Link
                  to="/#features"
                  className="p-3 cursor-pointer hover:text-white"
                  activeClassName="activeNav"
                >
                  Features
                </Link>
              )}
            </li>

            <li className="">
              {withScroll ? (
                <LinkReact
                  to="benefits"
                  className="p-3 cursor-pointer hover:text-white"
                  activeClass="activeNav"
                  spy={true}
                  smooth={true}
                  offset={-50}
                >
                  Benefits
                </LinkReact>
              ) : (
                <Link
                  to="/#benefits"
                  className="p-3 cursor-pointer hover:text-white"
                  activeClassName="activeNav"
                >
                  Benefits
                </Link>
              )}
            </li>

            <li className="">
              {withScroll ? (
                <LinkReact
                  to="uses"
                  className="p-3 cursor-pointer hover:text-white"
                  activeClass="activeNav"
                  spy={true}
                  smooth={true}
                  offset={-50}
                >
                  Uses
                </LinkReact>
              ) : (
                <Link
                  to="/#uses"
                  className="p-3 cursor-pointer hover:text-white"
                  activeClassName="activeNav"
                >
                  Uses
                </Link>
              )}
            </li>

            <li className="">
              {withScroll ? (
                <LinkReact
                  to="integration"
                  className="p-3 cursor-pointer hover:text-white"
                  activeClass="activeNav"
                  spy={true}
                  smooth={true}
                  offset={-50}
                >
                  Integration
                </LinkReact>
              ) : (
                <Link
                  to="/#integration"
                  className="p-3 cursor-pointer hover:text-white"
                  activeClassName="activeNav"
                >
                  Integration
                </Link>
              )}
            </li>

            <li className="">
              {withScroll ? (
                <LinkReact
                  to="pricing"
                  className="p-3 cursor-pointer hover:text-white"
                  activeClass="activeNav"
                  spy={true}
                  smooth={true}
                  offset={-50}
                >
                  Pricing
                </LinkReact>
              ) : (
                <Link
                  to="/#pricing"
                  className="p-3 cursor-pointer hover:text-white"
                  activeClassName="activeNav"
                >
                  Pricing
                </Link>
              )}
            </li>

            <li className="text-white">
              {withScroll ? (
                <LinkReact
                  to="evaluate"
                  className="p-3 cursor-pointer rounded bg-primary
              	hover:text-primary hover:bg-white"
                  activeClass="activeNavBig"
                  spy={true}
                  smooth={true}
                  offset={-50}
                >
                  Evaluate
                </LinkReact>
              ) : (
                <Link
                  to="/#evaluate"
                  className="p-3 cursor-pointer rounded bg-primary
              	hover:text-primary hover:bg-white"
                  activeClassName="activeNavBig"
                >
                  Evaluate
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
